import {
  AvailableSlotsInfo,
  BookingDetailsInfo,
  BookingRequestBody,
  ReplyBody,
  RequestBody,
  SupportRequestDetailsInfo,
  SupportRequestInfo,
} from '../modules/type';
import { request } from './request';
import { Account } from '@my-account/account';
import { auth, telemetry } from '@my-account/tools';
import Cookies from 'js-cookie';

export const saveSupportTicket = async (requestBody: RequestBody) => {
  await request('/api/cases', { method: 'POST', body: JSON.stringify(requestBody) });

  telemetry.segmentTrack('contactedSupport', {
    action: 'submissionSucceeded',
    subject: requestBody.subject,
    category: requestBody.category,
    subcategory: requestBody.subCategory,
    user_id: auth.getUser()?.userId || '',
    myob_visitor_id: Cookies.get('myob_visitor_id') || '',
    client_id: requestBody.clientId,
  });
};

export const fetchSupportRequestsByAccountId = async (
  accountId: Account['clientId'],
  isClosed: boolean,
): Promise<SupportRequestInfo[]> => {
  return request<SupportRequestInfo[]>(`/api/cases?clientId=${accountId}&isClosed=${isClosed}`);
};

export const fetchSupportRequestDetailsById = async (caseId: string): Promise<SupportRequestDetailsInfo> => {
  return request<SupportRequestDetailsInfo>(`/api/cases/${caseId}`);
};

export const replyRequestDetailsById = async (replyBody: ReplyBody) => {
  const caseId = replyBody.caseId;
  return request(`/api/cases/${caseId}`, { method: 'POST', body: JSON.stringify({ reply: replyBody.reply }) });
};

export const getAvailableSlots = async (region: string, clientId: string): Promise<AvailableSlotsInfo> => {
  return request<AvailableSlotsInfo>(
    `/api/premium-support-booking/available-slots?region=${region}&clientId=${clientId}`,
  );
};

export const saveBookings = async (requestBody: BookingRequestBody, region: string) => {
  await request(`/api/premium-support-booking/bookings?region=${region}`, {
    method: 'POST',
    body: JSON.stringify(requestBody),
  });
};

export const fetchPremiumSupportBookingDetailsById = async (bookingId: string) => {
  return request<BookingDetailsInfo>(`/api/premium-support-booking/bookings/${bookingId}`);
};

export const fetchAggregateSupportByClientId = async (
  clientId: Account['clientId'],
  isClosed: boolean,
): Promise<SupportRequestInfo[]> => {
  return request<SupportRequestInfo[]>(`/api/aggregate-support/cases?clientId=${clientId}&isClosed=${isClosed}`);
};
export const cancelBookingById = async (bookingId: string): Promise<void> => {
  return await request(`/api/premium-support-booking/bookings/${bookingId}`, { method: 'POST' });
};
